.site-header {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #FF3E17;
    .viessmann-top {
      text-align: right;
      margin-top: -15px;
      margin-bottom: 25px;
      .textwidget {
        float: right;
        padding-top: 2px;
        margin-left: 15px;
        font-size: 16px;
      }
    }
    
    
    #menu-main-menu {
      padding: 0;
      margin: 0;
      list-style: none;
      li.menu-item {
        float: left;
        a {
          padding: 5px 10px;
          color: #000;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 20px;
          letter-spacing: 1px;
          &:hover{
              background: #000;
              color: #fff;
          }
        }
      }    
      li.menu-item-has-children {
        ul.sub-menu {
          display: none;
        }
        &:hover {
          ul.sub-menu {
            display: block;
            padding: 0;
            margin: 0;
            z-index: 10;
            margin-top: 20px;
            list-style: none;
            width: 250px;
            position: absolute;
            background: theme-color(warning);
            li.menu-item {
              width: 100%;
              a {
                padding: 10px;
                display: block;
                &:hover{
                    background: rgba(#0295ca, 1);
                    color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .navbar-toggler {
        padding: 10px;
        margin-left: auto;
        background: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
        &:focus{
            outline: none
        }
    }

    .navbar-toggler-icon {
        width: 22px;
        height: 1px;
        margin: 5px;
        display: block;
        background: theme-color(primary);
    }
    
    .menu-main-menu-container {
        position: absolute;
        right: 0;
        @media (max-width:991px) {
            background: #fff !important;
            border-top: 2px solid theme-color(primary);
            padding-top: 15px;
            padding-bottom: 15px;
            z-index: 999;
            #menu-main-menu {
                .menu-item {
                  width: 100%;
                  a {
                    color: $body-color;
                    padding: 15px !important;
                    display: block;
                  }
                }
            }
        }
    }
    
    .site-nav {
        .social {
            padding: 15px;
            a{
                color: #0295ca;
            }
        }

    }
}
.header-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width:992px) {
            justify-content: flex-end;
        }
        &__item:first-child{
            border-right: 1px solid #ccd8e0;
        }
    .media-body {
        h4 {
            font-size: 18px;
            font-weight: 600;
            color: #003a63;
            text-transform: uppercase;
        }
        a{
            font-size: 22px;
            color: #003a63;
            font-weight: bold;
        }
    }
}

.social {
    a{
        width: 30px;
        height: 30px;
        display: block;
        background: #fff;
        color: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 85%;
        margin: 3px 5px;
        &:hover{
            background: theme-color(warning);
        }
    }
}

.intro-hero{
    background-size: cover !important;
    background-position: center center;    
    background-repeat:  no-repeat;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .header-text {
      text-align: center;
      padding: 50px;
      font-size: 48px;
      background: rgba(0,0,0,0.4);
      color: #fff;
      @media (max-width:1200px) {
        font-size: 32px;
      }
      @media (max-width:992px) {
        font-size: 24px;
      }
    }
}
.home {
  .intro-hero{
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 0px;
      @media (min-width:992px) {
          padding-top: 150px;
          padding-bottom: 200px;
          height: 85vh;
      }
  }
}