
  ul.slick-dots {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      margin-top: 30px;
      > li {
        display: inline-block;
        &.slick-active {
          .slick-dot {
            width: 20px;
            border-radius: 5px;
          } 
        }
      }
  }
  span.slick-dot {
      width: 10px;
      height: 10px;
      background: theme-color(primary);
      border: 2px solid theme-color(primary);
      display: inline-block;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 8px;
      transition: 0.5s;
  }
  
  .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: 0.3s;
      width: 45px;
      height: 45px;
      border: 1px solid #ffc80c;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffc80c;
      font-size: 18px;
      &:hover{
          background: #ffc80c;
          color: #fff;
      }
  }

  
  .slick-prev {
    left:15px;
    z-index: 1030;
  }
  
  .slick-next {
    right:15px;
    z-index: 1030;
  }
  
.slick-slide{
    &:focus{
        outline: none;
    }
}