.footer-contact {
    background: #BABABA;
    padding-bottom: 10px;
    padding-top: 30px;
    .wpcf7 {
      label {
        width: 100%;
      }
      input, textarea {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eee;
        max-height: 100px;
      }
    }
    .footer-contact-details {
      font-size: 20px;
      @media (max-width:767px) {
        text-align: center;
        font-size: 16px;
      }
      h3 {
        font-size: 30px;
        @media (max-width:767px) {
          font-size: 24px;
        }
      }
      img {
        margin-right: 20px;
        margin-top: 30px;
        @media (max-width: 767px){
          margin-right: 0;
        }
      }
    }
}

.site-footer {
    color: rgba(#fff, .8);
    background: #424242;
    font-size: 12px;
    
    .footer-top {
        padding-top: 15px;
        padding-bottom: 15px;        
        .footer-nav {
          ul{
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            li {
                margin-right: 15px;
                float: left;
                margin-bottom: 10px;
                @media (max-width: 767px){
                  display: inline-block;
                  float: none;
                  margin-right: 5px;
                  margin-left: 5px;
                  a {
                    font-size: 12px;
                  }
                }
            }
            li.menu-item-has-children {
              ul {
                display: none;
              }
            }
          }
        }
    }
    .footer-widget-title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        @media (max-width: 767px){
          text-align: center;
        }
    }
    
    .footer-bottom {
        @media (max-width: 767px){
          text-align: center;
        }
        .credit {
            text-align: right;
            @media (max-width: 767px){
              text-align: center;
            }
        }
    }
        
    @media (max-width: 767px){
      .my-4 {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }
    
}
