body {
    font-family: 'Roboto Condensed', sans-serif;
}

.iconbox {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: theme-color(warning);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    &.lg{
        width: 75px;
        height: 75px;
    } 
}
.btn {
    text-transform: uppercase;
}
.btn-pill{
    border-radius: 10rem;
}
.btn-outline-white {
    color: #fff;
    border-color: #fff;
    &:hover{
        background: #fff;
        color:theme-color(primary);
    }
}

.post {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-title {
    font-size: 36px;
    color: #000;
    margin-bottom: 20px;
    &.title-sm {
        font-size: 20px;
    }
}
.section {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width:992px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
.bg-img {
    background-size: cover !important;
    background-position: center center !important;
}

.pd60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 767px){
    .wp-block-column {
        flex-basis: 100%!important;
        margin-left: 0;
    }
}