
img {
  max-width: 100%;
}
 
a {
  text-decoration: none;
  color: inherit;
  transition: .3s; 
  &:hover{
    color: theme-color(primary);
    text-decoration: none;
  }
}

strong,
.strong {
  font-weight: 700;
}