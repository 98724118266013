
//// Boostrap 
@import "../vendor/bootstrap/scss/functions.scss";
// Extent and override bootstrap default variable
@import "variables";
@import "../vendor/bootstrap/scss/variables.scss";
@import "../vendor/bootstrap/scss/bootstrap.scss";

@import "partials/base";
@import "partials/global";
@import "partials/header";  
@import "partials/footer";  
@import "partials/index";
@import "partials/carousel";
