.hero{
    &-title {
        font-size: 34px;
        font-weight: 300;
        > span {
            font-weight: bold;
        }
    }
    &-tagline{
        font-size: 18px;
    }
}

.bms-block {
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    margin-top: 30px;
    &__title{
        font-size: 19px;
        font-weight: bold;
        color: #003a63;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 40px;
            height: 3px;
            background: theme-color(warning);
            left: 0;
            top: 100%;
            margin-top: 10px;
        }
    }
}

.buildingmanagement {
  background: url(../img/bg1.jpg) no-repeat;
}

.members {
  background:#f6fdff
}

.contact-tagline {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}

.contact-form {
    .col-md-6,
    .col-12{
        padding-left: 10px;
        padding-right: 10px;
    }
    .form-control{
        margin-bottom: 20px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        height: auto !important;
        border-radius: 0;
        border-color: transparent;
    }
    .btn{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}